const agentES = {
  'agent.venue_details': 'Detalles del Lugar',
  'agent.venue_xrefid': 'ID del Lugar',
  'agent.venue_xrefid_value': 'ID del Lugar: {{venueXRefID}}',
  'agent.salesforce_xrefid': 'Salesforce ID',
  'agent.dba_name': 'Nombre de DBA',
  'agent.timezone': 'Zona horaria',
  'agent.is_test': 'Es Prueba?',
  'agent.service_time': 'Tiempo de servicio',
  'agent.start_of_week': 'Comienzo de semana',
  'agent.currency': 'Divisa',
  'agent.locale': 'Lugar',
  'agent.legacy_integrations': 'Integraciones heredadas',
  'agent.phone': 'Teléfono',
  'agent.email': 'Correo electrónico',
  'agent.venue_details_unavailable': 'Detalles del lugar no disponibles',
  'agent.venue_address': 'Dirección del Lugar',
  'agent.venue_address_line1': 'Dirección Línea 1',
  'agent.venue_address_line2': 'Dirección Línea 2',
  'agent.corporation_details': 'Detalles del Corporación',
  'agent.corporation_name': 'Nombre',
  'agent.corporation_xrefid': 'ID del Corporación',
  'agent.corporation_admin_email': 'Correo de administración del Corporativo',
  'agent.corporation_signup_created':
    'El correo electrónico de registro de la corporación ha sido enviado a {{email}}',
  'agent.corporation_invite_resent':
    'El correo electrónico de registro de la corporación ha sido reeenviado',
  'agent.corporation_name_successfully_updated': 'Nombre de la corporación actualizado con éxito',
  'agent.salesforce_xrefid_null_error': 'Error: SalesforceXRefID no puede ser nulo',
  'agent.error_user_already_exists': 'Error: El usuario ya existe',
  'agent.unexpected_error': 'Ocurrió un error inesperado.',
  'agent.venue_address_state': 'Estado',
  'agent.venue_address_city': 'Ciudad',
  'agent.venue_address_country': 'País',
  'agent.venue_address_postal_code': 'Código postal',
  'agent.venue_active_entitlements': 'Derechos Activos',
  'agent.venue_entitlement_properties': '[ES] Entitlement Properties',
  'agent.active_entitlement_name': 'Nombre',
  'agent.active_entitlement_description': 'Descripción',
  'agent.venue_integration_name': 'Nombre',
  'agent.venue_integration_description': 'Descripción',
  'agent.no_active_venue_entitlements': 'El lugar no tiene derechos activos',
  'agent.no_enabled_integrations': 'El lugar no tiene integraciones habilitadas',
  'agent.venue_enabled_integrations': 'Integraciones habilitadas',
  'agent.licence_xrefid': 'Id',
  'agent.licence_start_date': 'Fecha de inicio',
  'agent.licence_expiry_date': 'Fecha de caducidad',
  'agent.licence_void_date': 'Fecha de nula',
  'agent.licence_actual_end_date': 'Fecha de finalización real',
  'agent.licence_type': 'Escribe',
  'agent.licence_pos_count': 'Conteo de almohadillas',
  'agent.licence_cfd_count': 'Recuento de CFD',
  'agent.licence_status': 'Estado',
  'agent.licence_pos_restaurant_xrefid': 'UUID',
  'agent.licence_status_valid': 'Válida',
  'agent.licence_status_voided': 'Anulada',
  'agent.licence_status_expired': 'Caducada',
  'agent.licence_issuance_reason': 'Razón de emisión',
  'agent.licence_issuance_reason_demo': 'Demostración',
  'agent.licence_issuance_reason_collections': 'Colecciones',
  'agent.licence_issuance_reason_upgrade': 'Mejora',
  'agent.licence_issuance_reason_new_or_renewal': 'Nueva / Renovación',
  'agent.licence_issuance_reason_prelive': 'Previve',
  'agent.licence_issuance_reason_temporary_extension': 'Extensión Temporal',
  'agent.licence_issuance_reason_unknown': 'Desconocida',
  'agent.void_licence': 'Hacer vacía',
  'agent.unlink_licence': 'Desvincular licencia',
  'agent.unlink_licence_confirm': '¿Estás segura de que quieres desvincular esta licencia?',
  'agent.void_licence_confirm': '¿Está seguro de que desea anular esta licencia?',
  'agent.licence_success': 'Licencias cambiadas con éxito',
  'agent.licence_error': 'Ocurrió un error al actualizar esta licencia',
  'agent.create_licence': 'Crear licencia',
  'agent.evergreen_licence_button': 'Hojas perennes',
  'agent.create_licence_header': 'Crear licencia',
  'agent.licences_table_header': 'Licencias',
  'agent.no_pos_checkin': 'El lugar no tiene registros de POS',
  'agent.pos_diagnostics': 'Diagnóstico de POS',
  'agent.no_pos_diagnostics': 'El lugar no tiene diagnósticos de POS',
  'agent.checkin_settings': 'Ajustes',
  'agent.pos_diagnostics_payment_gateway_settings': 'Ajustes',
  'agent.pos_diagnostics_printers': 'Impresoras',
  'agent.pos_diagnostics_settings': 'Configuración del restaurante',
  'agent.pos_diagnostics_no_printers': 'El lugar no tiene impresoras',
  'agent.pos_diagnostics_printer_ip_address': 'Dirección IP',
  'agent.pos_diagnostics_printer_name': 'Nombre',
  'agent.pos_diagnostics_printer_type': 'Escribe',
  'agent.pos_diagnostics_payment_terminals': 'Terminales de pago',
  'agent.pos_diagnostics_diagnosis_time_utc': 'Hora de diagnóstico UTC',
  'agent.pos_diagnostics_time_since_diagnosis': 'Tiempo desde el diagnóstico',
  'agent.pos_diagnostics_pos_uuid': 'UUID de POS',
  'agent.pos_diagnostics_device_id': 'ID del dispositivo',
  'agent.pos_diagnostics_pos_version': 'Versión POS',
  'agent.pos_diagnostics_client_ip': 'IP del cliente',
  'agent.pos_diagnostics_device_settings': 'Configuración de dispositivo',
  'agent.pos_diagnostics_main_device': 'Dispositivo principal',
  'agent.pos_diagnostics_main_device_name': 'Nombre',
  'agent.pos_diagnostics_main_device_ip': 'Dirección IP',
  'agent.pos_diagnostics_main_device_os': 'Sistema operativo',
  'agent.pos_diagnostics_main_device_network_name': 'Nombre de red',
  'agent.pos_diagnostics_main_device_running_time_seconds': 'Tiempo de funcionamiento en segundos',
  'agent.pos_diagnostics_device_storage': 'Almacenamiento de dispositivos',
  'agent.pos_diagnostics_device_storage_free_space': 'Espacio libre',
  'agent.pos_diagnostics_device_storage_data_size': 'Tamaño de datos',
  'agent.pos_diagnostics_device_storage_db_size': 'Tamaño de la base de datos',
  'agent.pos_diagnostics_venue_operations': 'Operaciones del lugar',
  'agent.pos_diagnostics_venue_operations_last_app_start': 'Último inicio de aplicación',
  'agent.pos_diagnostics_venue_operations_last_day_start': 'Último día de inicio',
  'agent.pos_diagnostics_venue_operations_last_day_end': 'Fin del último día',
  'agent.pos_diagnostics_venue_operations_last_restaurant_open': 'Último restaurante abierto',
  'agent.pos_diagnostics_payment_gateway': 'Pasarela de pago',
  'agent.pos_diagnostics_no_payment_gateway': 'Venue no ha configurado una pasarela de pago',
  'agent.pos_diagnostics_payment_gateway_gateway_name': 'Nombre de la puerta de enlace',
  'agent.pos_diagnostics_no_payment_terminals': 'El lugar no tiene terminales de pago',
  'agent.pos_diagnostics_payment_terminal_ip_address': 'Dirección IP',
  'agent.pos_diagnostics_payment_terminal_name': 'Nombre',
  'agent.pos_diagnostics_payment_terminal_type': 'Escribe',
  'agent.pos_diagnostics_payment_terminal_id': 'ID de terminal',
  'agent.expand': 'Expandir',
  'agent.collapse': 'Collapse',
  'agent.audit_logs': 'Registros de Auditoría',
  'agent.no_audit_logs': 'El lugar no tiene registros de auditoría',
  'agent.audit_logs_created_at': 'Creado en',
  'agent.audit_logs_event': 'Evento',
  'agent.audit_logs_event_type': 'Tipo de Evento',
  'agent.audit_logs_description': 'Descripción',
  'agent.audit_logs_actioned_by': 'Actuado Por',
  'agent.patches': 'parches',
  'agent.support_patch_code': 'Código de parche',
  'agent.support_patch_restaurant_uuid': 'Uuid del restaurante',
  'agent.support_patch_content': 'Contenido',
  'agent.support_patch_auto_apply': 'Se puede aplicar automáticamente al inicio',
  'agent.support_patch_result': 'Resultado del parche',
  'agent.support_patch_comments': 'Comentarios del parche',
  'agent.support_patch_create_date': 'Fecha de creación (UTC)',
  'agent.support_patch_patch_date': 'Fecha del parche (UTC)',
  'agent.support_patch_created_by': 'Creado por',
  'agent.no_support_patches': 'Venue no tiene parches de soporte',
  'agent.support_patch_create': 'Crear parche',
  'agent.support_patch_sql': 'Parche SQL',
  'agent.support_patch_sql_error': 'Se requiere SQL',
  'agent.support_patch_created_success': 'Parche de soporte creado con éxito',
  'agent.support_patch_can_auto_apply': 'Se puede aplicar automáticamente al inicio',
  'agent.support_patch_code_create': 'Crear código de parche',
  'agent.venue_devices': 'Dispositivos',
  'agent.no_venue_devices': 'El lugar no tiene dispositivos vinculados',
  'agent.venue_devices_device_id': 'Identificación del dispositivo',
  'agent.venue_devices_name': 'Nombre',
  'agent.venue_devices_last_connected': 'Última conexión (local)',
  'agent.venue_devices_app_name': 'Nombre de la aplicación',
  'agent.venue_devices_os': 'OS',
  'agent.venue_devices_hardware': 'Hardware',
  'agent.venue_devices_os_version': 'Versión de la OS',
  'agent.request_sql_file': 'Solicitar Archivo SQL',
  'agent.request_sql_file.success': 'Archivo SQL solicitado de POS para soporte',
  'agent.request_sql_file.error': 'Hubo un error al solicitar el archivo SQL, intente nuevamente',
  'agent.request_log_file': 'Solicitar archivo de registro',
  'agent.request_log_file.success': 'Archivo de registro solicitado desde POS para soporte',
  'agent.request_log_file.error':
    'Se produjo un error al solicitar el archivo de registro. Inténtelo de nuevo.',
  'agent.request_log_file.info': 'Solicitar registros de pagos y dispositivos desde POS',
  'agent.latest_backup_uploads': 'Últimas cargas de copia de seguridad',
  'agent.ftp_sql_dir': 'Directorio FTP SQL',
  'agent.stop_mimicking': '[ES] Stop Viewing As',
  'agent.stop_mimicking.success': '[ES] Successfully stopped viewing user',
  'agent.stop_mimicking.error':
    '[ES] An error occurred while trying to stop viewing user, please try again',
  'agent.shard_name': 'Nombre del fragmento',
  'agent.assign_to_prod_shard': '¿Asignar a Prod Shard?',
  'agent.assign_to_dev_shard': '¿Asignar a Dev Shard?',
  'agent.venue_sync_status': 'Estado de Sincronización',
  'agent.amplisync_first_sync_is_done': 'La primera sincronización está hecha',
  'agent.amplisync_last_sync_date': 'Última fecha de sincronización',
  'agent.amplisync_state': 'Estado de sincronización',
  'agent.amplisync_sync_down_enabled': 'Sincronización hacia abajo habilitada',
  'agent.amplisync_sync_up_enabled': 'Sincronización habilitada',
  'agent.amplisync_sync_subscriber_id': 'ID de suscriptor',
  'agent.amplisync_shardxrefid': 'ShardXRefID',
  'agent.amplisync_last_completed_sync_date': 'Última fecha de sincronización completada',
  'agent.venue_sync_status_unavailable': 'El estado de sincronización del lugar no está disponible',
  'agent.venue_id': 'Identificacion del Local',
  'agent.table_of_contents': 'Tabla de contenido',
  'agent.venue_diagnostics_title': 'Diagnóstico',
  'agent.venue_amplisync_details': 'Detalles de Amplisync',
  'agent.view_venue_details_snackbar': 'Busque y presione Ver para ver los detalles de un Lugar',
  'agent.resync.create_resync': 'Crear Resync',
  'agent.resync.id': 'ID',
  'agent.resync.start_date': 'Fecha de inicio',
  'agent.resync.end_date': 'Fecha final',
  'agent.resync.resync_type': 'Tipo de resincronización',
  'agent.resync.acknowledged_at': 'Reconocido en',
  'agent.resync.notes': 'Notas',
  'agent.resync.created_by': 'Creado por',
  'agent.resync.created_at': 'Creado en',
  'agent.resync.warning':
    'NO UTILIZAR: esta página está diseñada para que la usen únicamente los equipos de nivel 3 y de desarrollo. Si necesita que se ejecute una Resync y no es parte de DevIT, escale un ticket al nivel 3 para la acción.',
  'agent.resync.authorized_confirmation': 'Estoy autorizado a crear Resyncs',
  'agent.resync.perform_resync': 'Realizar Resync',
  'agent.send_invite': 'Enviar invitación',
  'agent.resend_invite': 'Reenviar invitación',
  'agent.amplisync_operations.sync_enablement_header': 'Habilitación de sincronización',
  'agent.amplisync_operations.venue_ids_label':
    'ID de lugar o lista de ID de lugares separados por comas',
  'agent.amplisync_operations.venue_id_label': 'Identificación del lugar',
  'agent.amplisync_operations.sync_up': 'Sincronizar',
  'agent.amplisync_operations.sync_down': 'Sincronizar hacia abajo',
  'agent.amplisync_operations.demo_mode': 'Modo de demostración',
  'agent.amplisync_operations.sync_enablements_update_error':
    'Error al actualizar las habilitaciones del lugar',
  'agent.amplisync_operations.sync_enablements_fetch_error':
    'Error al obtener las habilitaciones del lugar',
  'agent.amplisync_operations.sync_enablements_update_success':
    'Habilitaciones actualizadas con éxito',
  'agent.amplisync_operations.sync_enablement_button': 'Guardar cambios',
  'agent.amplisync_batch_reset': 'Reinicio por lotes de Amplisync',
  'agent.amplisync_operations.enablements_not_found':
    'No se encontraron lugares con las identificaciones dadas',
  'agent.amplisync_operations.reset_amplisync': 'Restablecer Amplisync',
  'agent.amplisync_operations.batch_reset': 'Reinicio por lotes',
  'agent.amplisync_operations.batch_reset_modal_header':
    '¿Desea realizar un reinicio por lotes para lugares: ',
  'agent.amplisync_operations.batch_reset_success': 'Los lugares se reinician con éxito',
  'agent.amplisync_operations.batch_reset_error': 'Los lugares no se pudieron restablecer',
  'agent.domain_data_xfer.title': '[ES] Domain Data Transfer',
  'agent.domain_data_xfer.confirm': '[ES] Confirm',
  'agent.domain_data_xfer.confirm_transfer_title': '[ES] Confirm Transfer',
  'agent.domain_data_xfer.confirm_transfer':
    "[ES] Please confirm you want to transfer '{{domain}}' data from '{{sourceBaseName}}' - {{sourceBaseXRefID}} to '{{targetBaseName}}' - {{targetBaseXRefID}}",
  'agent.domain_data_xfer.success':
    "[ES] Success transferring '{{domain}}' data from '{{sourceBaseName}}' - {{sourceBaseXRefID}} to '{{targetBaseName}}' - {{targetBaseXRefID}}",
  // RMM3 Upgrade
  'agent.domain_data_xfer.source_base': '[ES] Select a source venue',
  'agent.domain_data_xfer.source_base_description': '[ES] Data to be transferred from this venue',
  'agent.domain_data_xfer.target_base': '[ES] Select a target venue',
  'agent.domain_data_xfer.target_base_description': '[ES] Data to be transferred to this venue',
  'agent.domain_data_xfer.hierarchy_data_option': '[ES] Select a hierarchy data option',

  'agent.domain_data_xfer.wipe_data_option_warning':
    '[ES] Wipe Option = This will wipe menu data from all venues except from source base before elevate data. If there are existing data at root/group, please delete them before select this option. There is no rollback',
  'agent.domain_data_xfer.no_wipe_data_option_warning':
    '[ES] No Wipe Option = This will elevate data and keep all exisiting data and overrides data from root/group and other venues',
  'agent.domain_data_xfer.wipe_data_option': '[ES] Wipe all menu data then elevate',
  'agent.domain_data_xfer.merge_data_option': '[ES] Do not wipe any menu data before elevation',
  'agent.domain_data_xfer.domain': '[ES] Domain',
  'agent.domain_data_xfer.no_domain_info': '[ES] No upgradeable domains found',
  'agent.rmm3_upgrade.venues_to_upgrade': 'Venues To Upgrade',
  'agent.upgrade': '[ES] Upgrade',
  'agent.rmm3_upgrade': '[ES] RMM3 Upgrade',
  'agent.rmm3_upgrade_do_not_touch': '[ES] RMM3 Upgrade (DO NOT TOUCH)',
  'agent.rmm3_upgrade_name': '[ES] Name (links to details)',
  'agent.rmm3_upgrade_venueid': '[ES] Venue ID (links to DataDog)',
  'agent.rmm3_upgrade_status': '[ES] Upgrade Status',
  'agent.rmm3_upgrade_error': '[ES] Upgrade Error',
  'agent.rmm3_upgrade_error_err_non_supported_pos_version':
    'Venue is using a non-supported POS version',
  'agent.rmm3_upgrade_error_err_inclusive_tax_error': '[ES] Venue has inclusive tax enabled',
  'agent.rmm3_upgrade_error_err_rmm3_migration_failed': '[ES] RMM3 Upgrade Failed',
  'agent.rmm3_upgrade.stats_title': '[ES] Stats for customer accounts',
  'agent.rmm3_upgrade.stats':
    "[ES] We've attempted upgrades for {{total}} customer venues: {{success}} have succeeded and {{fail}} have failed, the other {{rest}} are in progress.",
  'agent.rmm3_upgrade.are_you_sure': 'Are you sure you want to upgrade these venues?',
  'agent.rmm3_upgrade.warning_message':
    '<1>Please take the time to confirm the following venues are valid.</1><2>NOTE: Attempting to upgrade a venue may cause disruptions to live customers during service hours. If you are unsure, please click cancel and leave this page immediately.</2>',
  'agent.rmm3_upgrade.upgrade_statuses': 'Upgrade Statuses',
  'agent.rmm3_upgrade.upgrade_statuses_subtitle':
    'The following is a list of RMM3 upgrade statuses',
  'agent.rmm3_upgrade.upgrade_error': '[ES] {{errorCode}}',
  'agent.rmm3_upgrade.no_upgrades': 'No RMM3 Upgrades found',
  'agent.rmm3_upgrade.legend': 'Legend',
  'agent.rmm3_upgrade.statuses.completed':
    'Upgrade for this feature is complete. If all features have completed, the customer is on the new cloud platform.',
  'agent.rmm3_upgrade.statuses.failed_cloud':
    'There was an issue with the Cloud. Please review the error log in the dashboard and/or in the wiki',
  'agent.rmm3_upgrade.statuses.failed_pos':
    'There was an issue with the POS. Please review the error log in the dashboard and/or in the wiki',
  'agent.rmm3_upgrade.statuses.failed_validation':
    'The customer did not pass system and business validation. If you see this error, it means the customer is not a good fit to be part of the upgrade process',
  'agent.rmm3_upgrade.statuses.pending_pos':
    'The system is currently waiting for the latest POS data',
  'agent.rmm3_upgrade.statuses.pending_restart':
    'To complete the upgrade, please restart the POS main device/server',
  'agent.rmm3_upgrade.statuses.requested':
    'An agent has initiated the upgrade from the upgrades tool. We are requesting the latest data from the POS. Please restart the POS main device/server',
  'agent.rmm3_upgrade.statuses.started': 'The upgrade has started. Please wait at least 15 minutes',
  'agent.rmm3_upgrade.statuses.synced': 'POS Staff data has been uploaded to cloud successfully',
  'agent.rmm3_upgrade.success': 'Upgrades successfully requested',
  'agent.rmm3_upgrade.search': 'Search by name or ID',
  'agent.rmm3_upgrade.migration_type': '[ES] Migration Type',
  'agent.rmm3_upgrade.menu_migration': '[ES] Menu',
  'agent.rmm3_upgrade.staff_migration': '[ES] Staff',
  'agent.rmm3_upgrade.staff_migration.completed': '[ES]  COMPLETED',
  'agent.rmm3_upgrade.staff_migration.sync_error': '[ES]  SYNC ERROR',
  'agent.rmm3_upgrade.staff_migration.synced': '[ES] SYNCED',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_dupe_roles':
    '[ES] Duplicate roles with mismatched data: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_waiter_pin_missing':
    '[ES] Waiters missing pins: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_dupe_waiters':
    '[ES] Duplicate waiters with mismatched data: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_dupe_waiter_roles':
    '[ES] Duplicate waiterRoles with mismatched rateOfPay: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_role_baseID':
    '[ES] Existing roles not found on base: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_user_baseID':
    '[ES] Existing users not found on base: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_role_not_found':
    '[ES] Migrated roles not found: {{count}}',
  'agent.rmm3_upgrade.staff_migration.errors.err_staff_migration_user_not_found':
    '[ES] Migrated users not found: {{count}}',
  'agent.venue_pos_changes': 'Cambios en el punto de venta',
  'agent.venue_pos_version_changes': 'Cambios de versión',
  'agent.venue_pos_change_created_at': 'Creado en',
  'agent.venue_pos_change_old_value': 'Valor antiguo',
  'agent.venue_pos_change_new_value': 'Nuevo valor',
  'agent.no_pos_version_changes': 'Venue no tiene cambios de versión de POS',
  'agent.venue_pos_restaurant_uuid_changes': 'Cambios en el UUID del restaurante',
  'agent.venue_pos_device_id_changes': 'Cambios de ID de dispositivo',
  'agent.no_changes': 'El lugar no tiene cambios',
  'agent.no_restaurant_uuid_changes': 'El lugar no tiene cambios en el UUID del restaurante',
  'agent.no_device_id_changes': 'El lugar no tiene cambios de ID de dispositivo',
  'agent.venue_pos_time_since_change': 'Tiempo desde el cambio',
  'agent.user_mimic': '[ES] View as staff member',
  'agent.username.sort_by.venues': 'Lugares',
  'agent.username.sort_by.groups': 'Grupos',
  'agent.username.table.header.corporation': 'CORPORACIÓN',
  'agent.username.table.header.corporation_groups': 'CORPORACIÓN / GRUPOS',
  'agent.username.table.header.venues': 'LUGARES',
  'agent.username.table.header.roles': 'REGLAS',
  'agent.username.table.header.permission_type': 'TIPO DE PERMISO',
  'agent.view_staff': '[ES] View Staff',
  'agent.view_venues': '[ES] View Venues',
  'agent.sort_by': '[ES] Sort By',
  'agent.groups': '[ES] Groups',
  'agent.view_details': '[ES] View Details',
  'agent.add_a_new_group': '[ES] Add a New Group',
  'agent.group_name': '[ES] Group Name',
  'agent.group_id': '[ES] Group ID',
  'agent.reservation_entitlement_list': '[ES] TB Reservations in Cloud*',
  'agent.reservation_entitlement_title': '[ES] TB Reservations in Cloud',
  'agent.reservation_entitlement_caption':
    "* [ES] Provisions TB Reservations in Cloud only. Legacy Reservations is provisioned from the Reservation's admin tool.",
  'agent.reservation_entitlement_warning':
    'Save the Group and add Venues to it before adding TB Reservations in Cloud.',
  'agent.reservation_eligibility_warning':
    '[ES] Venues in this group are ineligible for TB Reservations in Cloud.',
  'agent.reservation_move_eligibility_warning':
    '[ES] Disabled Venues can not be moved to this Group because of entitlement conflicts.',
  'agent.group_assigned_venues': '[ES] Assigned Venues',
  'agent.group_move_venues': '[ES] Move Venues to this Group',
  'agent.group_move_venues_modal_title': '[ES] Move venues to {{group_name}}',
  'agent.group_move_venues_modal_subtitle':
    '[ES] Select venues that will be removed from other groups and added to this group.',
  'agent.group_move_venues_modal_submit': '[ES] Move to Group',
  'agent.group_assigned_products': '[ES] Guest Product Provisioning',
  'agent.group_unassigned_venue_err': '[ES] You need to assign all venues to groups',
  'agent.enter_a_group_name': '[ES] Please enter a group name',
  'agent.group_move_venues_to_unassigned': '[ES] Move Venues to Unassigned',
  'agent.group_move_venues_to_unassigned_modal_subtitle':
    '[ES] Select venues that will be removed from groups and added to corporation as unassigned venues.',
  'agent.group_move_venues_to_unassigned_modal_submit': 'Move to Unassigned',
  'agent.no_items_available': '[ES] No Items',
  'agent.products': '[ES] Products',
  'agent.baseXRefID': '[ES] BaseXRefID',
  'agent.ge_sync_required': '[ES] TableUp synchronization required.',
  'agent.ge_sync_title': 'Legacy TableUp synchronization required.',
  // Group Management
  'agent.group_management.post_save.modal_title': '[ES] Action Required!',
  'agent.group_management.post_save.staff.text1':
    '[ES] Please make sure Staff have been assigned permissions to the correct location. Staff that had permission to the group may no longer have access to the venue. Staff that had access to the old group should not have access to the venue anymore (unless the Staff has access to both groups).',
  'agent.group_management.post_save.menu.text1':
    '[ES] After moving the venue, publish your menu on Cloud to see the latest changes reflected. Failure to do so will result in the previous menu displaying on the POS and Online Ordering until a successful publish occurs. This includes any 3rd party integration that integrates with menu management. Please publish your menu when you are ready for the menus of the new groups to be reflected.',
  'agent.group_management.post_save.menu.text2':
    '[ES] Please make sure to re-assign printers to new prep stations, otherwise, it will result in the printers not working for the venue(s)',
  'agent.dgc': '[ES] Digital Gift Cards Admin',
  'agent.dgc.restaurantGroupName': '[ES] Restaurant Group',
  'agent.dgc.origin': '[ES] Origin',
  'agent.dgc.active': '[ES] Status',
  'agent.dgc.purchasedAtVenueXRefID': '[ES] Purchased At',
  'agent.dgc.search.placeholder': '[ES] Search by Reference ID or email address',
  'agent.dgc.empty_table_text': '[ES] Enter a reference ID or email address in the search bar',
  'agent.dgc.no_matching_result':
    '[ES] No matching results to display. Please try a different Reference ID or email address',
  'agent.dgc.deactivate': '[ES] Deactivate',
  'agent.dgc.reactivate': '[ES] Reactivate',
  'agent.dgc.deactivate.reason': '[ES] Deactivation Reason',
  'agent.dgc.deactivate.info': '[ES] Deactivation Information',
  'agent.dgc.deactivate.reason.placeholder': '[ES] Please enter a deactivation reason',
  'agent.dgc.info': '[ES] Gift Card Information',
  'agent.dgc.deactivated_successfully':
    '[ES] Gift Card {{referenceID}} is deactivated successfully!',
  'agent.dgc.reactivated_successfully':
    '[ES] Gift Card {{referenceID}} is reactivated successfully!',
  'agent.time': '[ES] Time',
  'agent.reason': '[ES] Reason',
  'agent.save_discard_changes_dialog_prompt':
    '[ES] Are you sure you want to leave this page without saving your changes?',
  'agent.dialog_save_changes': '[ES] Save Changes',
  'agent.dialog_discard_changes': '[ES] Discard Changes',
  'agent.changes_saved_successfully': '[ES] Changes made to {{groupName}} were successfully saved!',
  'agent.new_group_created_successfully': '[ES] Group created successfully',
  agent: '[ES] Agent',
}

export default agentES
