const orderingEN = {
  // namespace your translation keys with "ordering", i.e.
  // 'ordering.lorem_ipsum': 'Lorem Ipsum'
  'ordering.touchbistro_online_ordering': 'TouchBistro Online Ordering',
  'ordering.get_started': 'Get Started',
  'ordering.online_ordering_discovery_desc':
    'TouchBistro Online Ordering is an integrated solution that enables you to take pickup and delivery orders through your own website or TouchBistro Dine then streamlines them directly into your TouchBistro POS. Make online ordering more convenient for you and your diners while keeping more profit in your pocket.',
  'ordering.online_ordering_discovery_list_one_title': 'Increase Sales',
  'ordering.online_ordering_discovery_list_one_text':
    'Attract new diners with in-venue pickup and commission-free delivery through our DoorDash Drive integration or your own fleet of drivers. ',
  'ordering.online_ordering_discovery_list_two_title': 'Deliver a Great Guest Experience',
  'ordering.online_ordering_discovery_list_two_text':
    'Say goodbye to missed or inaccurate orders when they flow directly from your website to your point of sale in real-time. ',
  'ordering.online_ordering_discovery_list_three_title': 'Save Time and Money',
  'ordering.online_ordering_discovery_list_three_text':
    'Stay in control of your online orders and your bottom line by giving diners the opportunity to order directly from you, commission free!',
  'ordering.pos_order_settings': 'POS Order Settings',
  'ordering.pos_order_settings_warning':
    'POS order settings will be applied to any online order placed through TouchBistro Online Ordering, DoorDash Marketplace, Deliverect and/or Ordermark',
  'ordering.accepting_online_orders': 'Accepting Online Orders',
  'ordering.manual_accept_radio':
    'Accept orders manually on POS before they are sent to the kitchen',
  'ordering.auto_accept_radio': 'Automatically accept orders and immediately send them to kitchen',
  'ordering.auto_accept_one_device':
    'Auto-accepting of orders must be enabled on no more than one device in your restaurant. Ensure that the selected device is connected to all relevant printers in your restaurant. <1>Learn how to identify your desired Auto-Accept device here.</1>',
  'ordering.auto_accept_compatible_printers':
    'Some older printer models are not compatible with Auto-Accept for online orders. If any unsupported printer is in-use in your venue (either as a kitchen printer or receipt printer), please continue to manually accept online orders. <1>Check if your printer is supported here.</1>',
  'ordering.auto_accept_device_dropdown_title': 'Auto-Accept Device',
  'ordering.auto_accept_device_dropdown_placeholder': 'Select Device',
  'ordering.auto_accept_device_dropdown_option_label':
    '{{label}} (Device ID ending with: {{deviceID}})',
  'ordering.changes_saved': 'Your changes have been saved',
  'ordering.auto_accept_device_required':
    'A device is required for this setting. Please select a device. ',
  'ordering.no_connected_devices': 'You have no connected devices.',
  'ordering.connect_device': 'Connect a device',
  'ordering.pos_order_settings_err_unknown_code': 'Something went wrong. Please try again later.',
  'ordering.doordash.delivery_settings_doordash_disable': 'Disable DoorDash Integration',
  'ordering.doordash.using_doordash_fleet_information':
    'Don’t want to use DoorDash Drive? Return to your own delivery fleet',
  'ordering.doordash.switch_fleet_to_invenue_button': 'Switch to My Own Fleet',
  'ordering.doordash.switch_fleet_to_invenue_modal_title':
    'Are you sure you want to switch to your own delivery fleet?',
  'ordering.doordash.switch_fleet_to_invenue_modal_content_1':
    'By switching to your own delivery fleet, DoorDash drivers will no longer handle your delivery orders. You will be responsible for managing all of your deliveries.',
  'ordering.doordash.switch_fleet_to_invenue_modal_content_2':
    'You can switch back to the DoorDash Drive integration at any time.',
  'ordering.doordash.switch_fleet_to_doordash_button': 'Switch to DoorDash Drive',
  'ordering.doordash.switch_fleet_to_doordash_modal_title':
    'Are you sure you want to switch to the DoorDash Drive integration?',
  'ordering.doordash.switch_fleet_to_doordash_modal_content':
    'By using the DoorDash Drive integration, you agree to pay DoorDash a fee for every delivery.',
  'ordering.doordash.unsaved_fleet_modal_content':
    'Please save your delivery method before continuing.',
  'ordering.doordash.err_doordash_no_payment_method_configured':
    'A payment method has not been configured for this store.',
  'ordering.doordash.err_doordash_business_does_not_exist':
    'Business does not exist for this request.',
  'ordering.doordash.err_doordash_area_not_servicable': 'DoorDash does not serve this area.',
  'ordering.doordash.err_doordash_address_not_valid': 'Address cannot be found.',
  'ordering.doordash.err_doordash_pickup_address_not_serviceable':
    'Pickup address not serviceable.',
  'ordering.doordash.err_doordash_contract_not_recorded':
    'A contract has not been recorded for this business.',
  'ordering.doordash.err_doordash_customer_phone_invalid':
    'Phone number provided does not seem to be a valid one.',
  'ordering.doordash.err_template':
    '<1>Error:</1> {{message}} Please <2>contact support</2> to update this application.',
  'ordering.doordash.err_wrong_application':
    '<1>Error:</1> Something went wrong with application. Please <2>contact support</2> to update this application.',
  'ordering.invalid_venue_address_message':
    'Unfortunately, the current address is not applicable. Please update the address to continue with Online Ordering.',
  'ordering.webinar_banner.title': 'Webinars',
  'ordering.webinar_banner.body':
    "TouchBistro is now offering Online Ordering webinars that cover the basics and fundamentals of Online Ordering. Sign up if you're looking to learn more about using Online Ordering or if you’re already using Online Ordering. ",
  'ordering.webinar_banner.link': 'Learn more and register.',
}

export default orderingEN
