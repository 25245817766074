const orderingES = {
  'ordering.touchbistro_online_ordering': 'TouchBistro Online Ordering',
  'ordering.get_started': '[ES] Get Started',
  'ordering.online_ordering_discovery_desc':
    '[ES] TouchBistro Online Ordering is an integrated solution that enables you to take pickup and delivery orders through your own website or TouchBistro Dine then streamlines them directly into your TouchBistro POS. Make online ordering more convenient for you and your diners while keeping more profit in your pocket.',
  'ordering.online_ordering_discovery_list_one_title': 'Aumenta las ventas',
  'ordering.online_ordering_discovery_list_one_text':
    'Atrae a nuevos comensales con la recogida en el local y la entrega sin costo a través de nuestra integración con DoorDash Drive o tu propia flota de conductores.',
  'ordering.online_ordering_discovery_list_two_title': 'Ofrece una gran experiencia al comensal',
  'ordering.online_ordering_discovery_list_two_text':
    'Diles adiós a los pedidos perdidos o imprecisos ya que estos van directamente desde tu sitio web a tu punto de venta en tiempo real.',
  'ordering.online_ordering_discovery_list_three_title': 'Ahorra tiempo y dinero',
  'ordering.online_ordering_discovery_list_three_text':
    'Mantén el control de tus pedidos en línea y de tu rentabilidad ofreciendo a los comensales la oportunidad de hacer sus pedidos directamente contigo, ¡sin costo!',
  'ordering.pos_order_settings': 'Configuración de pedidos en el punto de venta',
  'ordering.pos_order_settings_warning':
    '[ES] POS order settings will be applied to any online order placed through TouchBistro Online Ordering, DoorDash Marketplace, Deliverect and/or Ordermark',
  'ordering.accepting_online_orders': 'Aceptación de pedidos en línea',
  'ordering.manual_accept_radio':
    'Acepta los pedidos manualmente en el punto de venta antes de enviarlos a la cocina.',
  'ordering.auto_accept_radio':
    'Acepta automáticamente los pedidos y envíelos inmediatamente a la cocina.',
  'ordering.auto_accept_one_device':
    'La aceptación automática de pedidos debe estar habilitada en no más de un dispositivo en tu restaurante. Asegúrese de que el dispositivo seleccionado esté conectado a todas las impresoras relevantes en su restaurante. <1>[ES] Learn how to identify your desired Auto-Accept device here.</1>',
  'ordering.auto_accept_compatible_printers':
    '[ES] Some older printer models are not compatible with Auto-Accept for online orders. If any unsupported printer is in-use in your venue (either as a kitchen printer or receipt printer), please continue to manually accept online orders. <1>Check if your printer is supported here.</1>',
  'ordering.auto_accept_device_dropdown_title': 'Dispositivo de aceptación automática',
  'ordering.auto_accept_device_dropdown_placeholder': 'Seleccionar dispositivo',
  'ordering.auto_accept_device_dropdown_option_label':
    '{{label}} (ID de dispositivo que termina en: {{deviceID}})',
  'ordering.changes_saved': 'Se han guardado los cambios',
  'ordering.auto_accept_device_required':
    'Se requiere un dispositivo para esta configuración. Seleccione un dispositivo.',
  'ordering.no_connected_devices': 'No tienes dispositivos conectados.',
  'ordering.connect_device': 'Conectar un dispositivo',
  'ordering.pos_order_settings_err_unknown_code':
    'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
  'ordering.doordash.delivery_settings_doordash_disable':
    'Deshabilitar la integración con DoorDash',
  'ordering.doordash.using_doordash_fleet_information':
    '¿No quieres usar DoorDash Drive? Regresa a tu propia flota de entrega.',
  'ordering.doordash.switch_fleet_to_invenue_button': 'Cambiar a Mi propia flota',
  'ordering.doordash.switch_fleet_to_invenue_modal_title':
    '¿Estás seguro de que deseas cambiar a tu propia flota de entrega?',
  'ordering.doordash.switch_fleet_to_invenue_modal_content_1':
    'Al cambiar a tu propia flota de entrega, los repartidores de DoorDash ya no manejarán tus pedidos de entrega. Tú serás responsable de gestionar todas tus entregas.',
  'ordering.doordash.switch_fleet_to_invenue_modal_content_2':
    'Puedes volver a la integración con DoorDash Drive en cualquier momento.',
  'ordering.doordash.switch_fleet_to_doordash_button': 'Cambiar a DoorDash Drive',
  'ordering.doordash.switch_fleet_to_doordash_modal_title':
    '¿Estás seguro de que deseas cambiar a la integración con DoorDash Drive?',
  'ordering.doordash.switch_fleet_to_doordash_modal_content':
    'Al utilizar la integración con DoorDash Drive, aceptas pagar a DoorDash una tarifa por cada entrega.',
  'ordering.doordash.unsaved_fleet_modal_content': 'Guarde su método de envío antes de continuar.',
  'ordering.doordash.err_doordash_no_payment_method_configured':
    'No se ha configurado un método de pago para esta tienda.',
  'ordering.doordash.err_doordash_business_does_not_exist':
    'El negocio no existe para esta solicitud.',
  'ordering.doordash.err_doordash_area_not_servicable': 'DoorDash no sirve en esta zona',
  'ordering.doordash.err_doordash_address_not_valid': 'No se puede encontrar la dirección.',
  'ordering.doordash.err_doordash_pickup_address_not_serviceable':
    'Dirección de recogida no disponible',
  'ordering.doordash.err_doordash_contract_not_recorded':
    'No se ha registrado un contrato para este negocio.',
  'ordering.doordash.err_doordash_customer_phone_invalid':
    'El número de teléfono proporcionado no parece ser válido.',
  'ordering.doordash.err_template':
    '<1>Error:</1> {{message}} Póngase en <2>contacto con el soporte</2> para actualizar esta aplicación.',
  'ordering.doordash.err_wrong_application':
    'Algo salió mal con la aplicación. Póngase en <2>contacto con el soporte</2> para actualizar esta aplicación.',
  'ordering.invalid_venue_address_message':
    'Desafortunadamente, la dirección actual no es aplicable. Actualice la dirección para continuar con los pedidos en línea.',
  'ordering.webinar_banner.title': 'Seminarios web',
  'ordering.webinar_banner.body':
    "[ES] TouchBistro is now offering Online Ordering webinars that cover the basics and fundamentals of Online Ordering. Sign up if you're looking to learn more about using Online Ordering or if you’re already using Online Ordering. ",
  'ordering.webinar_banner.link': '[ES] Learn more and register.',
}

export default orderingES
