const reservationsES = {
  'reservations.reservations': '[ES] Reservations',
  'reservations.settings': '[ES] Settings',
  'reservations.reservations-settings': '[ES] Reservations Settings',
  'reservations.online-reservations-waitlist-settings':
    '[ES] Online Reservations & Waitlist Settings',
  'reservations.enable-in-house-reservations': '[ES] Enable In-House Reservations and Waitlist',
  'reservations.always-enabled': '[ES] This is always enabled for all shifts.',
  'reservations.online-reservations-waitlist': '[ES] Online Reservations Waitlist',
  'reservations.online-reservations-waitlist-description':
    '[ES] Allow guests to add themselves to a Reservation Waitlist if their preferred booking time is unavailable. You must contact these guests to inform them if their booking request can be accepted.',
  'reservations.discover-online': '[ES] Discover Online',
  'reservations.discover-online-description':
    '[ES] Guests can search for your restaurant on TouchBistro Dine and book a table through Reserve with Google.',
  'reservations.enable-walk-in-waitlist': '[ES] Enable Online Walk-In Waitlist',
  'reservations.enable-walk-in-waitlist-description':
    '[ES] Reserve with Google allows guests to join your waitlist online and be notified of their place in line. This applies to all shifts.',
  'reservations.minimum': '[ES] Minimum',
  'reservations.maximum': '[ES] Maximum',
  'reservations.min-party-size': '[ES] Minimum Party Size',
  'reservations.max-party-size': '[ES] Maximum Party Size',
  'reservations.save': '[ES] Save',
  'reservations.tb-dine-page': '[ES] TB Dine Page',
  'reservations.customize-tb-dine-page': '[ES] Customize your TB Dine Page',
  'reservations.fill-out-restaurant-profile':
    '[ES] Fill out your restaurant profile with information that guests will use to....',
  'reservations.view-restaurant-profile': '[ES] View Restaurant Profile',
  'reservations.view-tb-dine-page': '[ES] View TB Dine Page',
  'reservations.settings-updated-success': '[ES] Your settings have benn successfully updated.',
  'reservations.availability-planning': '[ES] Availability Planning',
  'reservations.overrides': '[ES] Overrides',
  'reservations.overrides-name': '[ES] Override Name',
  'reservations.overrides-description':
    '[ES] Create Shifts Overrides when you need to change your schedule settings on days that are not part of your typical schedule.',
  'reservations.override-create-new-button': '[ES] Create New Override',
  'reservations.service-period': '[ES] Service Period',
  'reservations.7_days_recurring_schedule': '[ES] 7 Days Recurring Schedule',
  'reservations.7_days_recurring_schedule.info':
    '[ES] Preview and schedule recurring Shifts for each Day of the Week and Service Period (SP).',
  'reservations.sp_1': '[ES] SP 1',
  'reservations.sp_2': '[ES] SP 2',
  'reservations.monday': '[ES] monday',
  'reservations.tuesday': '[ES] tuesday',
  'reservations.wednesday': '[ES] wednesday',
  'reservations.thursday': '[ES] thursday',
  'reservations.friday': '[ES] friday',
  'reservations.saturday': '[ES] saturday',
  'reservations.sunday': '[ES] sunday',
  'reservations.create_new_shift': '[ES] Create New Shift',
  'reservations.edit_shift': '[ES] Edit Shift',
  'reservations.shifts': '[ES] Shifts',
  'reservations.shifts-management': '[ES] Shifts Management',
  'reservations.shifts-management-description':
    '[ES] Create and manage your recurring or one-time Shifts. Set Shifts time, Table Availability, Pacing and Turn Times and schedule each Shift on the 7 Day Recurring Schedule table above.',
  'reservations.create-new-shift': '[ES] Create New Shift',
  'reservations.shift-name': '[ES] SHIFT NAME',
  'reservations.shift-start-and-end-time': '[ES] START & END TIME',
  'reservations.no-existing-shifts': '[ES] You currently have no Shifts to be displayed.',
  'reservations.closures': '[ES] Closures',
  'reservations.closures-description':
    '[ES] Block booking for specific upcoming dates or date ranges. Closures will turn off all Online and In House availability and will cancel any pre-booked reservations for those dates.',
  'reservations.closures-create-button': '[ES] Create New Closure',
  'reservations.closures-name': '[ES] Closure Name',
  'reservations.closures-sp': '[ES] Service Period',
  'reservations.closures-type': '[ES] Closure Type',
  'reservations.empty-setting-message': '[ES] You currently have no {{type}} to be displayed.',
  'reservations.none': '[ES] None',
  'reservations.step_of_total': '[ES] STEP {{current}}/{{total}}',
  'reservations.shift_details': '[ES] Shift Details',
  'reservations.shift_details.description':
    '[ES] Specify the shift name and when you would like the shift to occur.',
  'reservations.shift_name': '[ES] Shift Name',
  'reservations.max_n_chars': '[ES] Maximum {{limit}} characters',
  'reservations.first_seating': '[ES] First Seating',
  'reservations.last_seating': '[ES] Last Seating',
  'reservations.availability': '[ES] Availability',
  'reservations.availability.description':
    '[ES] Specify how the system will manage online reservations.',
  'reservations.restrict_reservations_to_waitlist':
    '[ES] Restrict Online Reservations to be Waitlist Only',
  'reservations.restrict_reservations_to_waitlist.description':
    '[ES] If enabled, guests will only be able to add themselves to waitlist using TB Dine and the booking widget on your website for the days this shift is scheduled.',
  'reservation.note_bold': '[ES] Note:',
  'reservation.restrict_note_online_reservation_waitlist_link_text':
    '[ES] Online Reservation Waitlist',
  'reservation.restrict_note_description_test': '[ES] must be turned ON to use this setting.',
  'reservations.table_availability': '[ES] Table Availability',
  'reservations.table_availability.description':
    '[ES] Select the floor plans you want available during your shift. Click “Edit Table Availability” to manage the availabilities of tables and combinations within a floor plan.',
  'reservations.floor_plan_name': '[ES] Floor Plan Name',
  'reservations.max_capacity': '[ES] Max Capacity',
  'reservations.available_capacity': '[ES] Available Capacity',
  'reservations.min_party': '[ES] Min Party',
  'reservations.max_party': '[ES] Max Party',
  'reservations.online_and_in_house': '[ES] Online & In House',
  'reservations.in_house_only': '[ES] In House Only',
  'reservations.online_only': '[ES] Online Only',
  'reservations.availability_empty_content':
    '[ES] There are no Floor Plans with Tables created for your Venue. To assign Tables that can be reserved for the Shift, you should have at least one Floor Plan created and Tables added to it.',
  'reservations.edit_table_availability': '[ES] Edit Table Availablity',
  'reservations.edit_table_availabilities': '[ES] Edit Table Availabilities',
  'reservations.go_to_floor_plan': '[ES] Go to Floor Plan',
  'reservations.pacing': '[ES] Pacing',
  'reservations.pacing.description':
    '[ES] Pacing allows you to control the maximum number of parties and guests that can book reservations every 15 minutes. Applies to Online & In House bookings for this shift.',
  'reservations.time': '[ES] Time',
  'reservations.max_number_parties': '[ES] Max Number of Parties',
  'reservations.max_number_guests': '[ES] Max Number of Guests',
  'reservations.same_pacing': 'Same pacing all shift for every 15 minute interval',
  'reservations.no_limit': '[ES] No Limit',
  'reservations.turn_times': '[ES] Turn Times',
  'reservations.turn_times.description':
    '[ES] Specify the expected turn times for each party size.',
  'reservations.party_size': '[ES] Party Size',
  'reservations.expected_time': '[ES] Expected Time (HH / MM)',
  'reservations.party_size.guests': '[ES] {{size}} Guests',
  'reservations.hours': '[ES] hour(s)',
  'reservations.min': '[ES] min',
  'reservations.item_created': '"[ES] {{item_name}}" has been successfully created.',
  'reservations.item_updated': '"[ES] {{item_name}}" has been successfully updated.',
  'reservations.duration': '[ES] Duration',
  'reservations.back_to_create_shift': '[ES] Back to Create New Shift',
  'reservations.select_floorplan_to_edit': '[ES] Select floor plan to edit',
  'reservations.table_and_combinations': '[ES] Table / Combinations',
  'reservations.table_priority': '[ES] Table Priority',
  'reservations.min_seats': '[ES] Min Seats',
  'reservations.max_seats': '[ES] Max Seats',
  'reservations.table_type': '[ES] Table Type',
  'reservations.adult_only': '[ES] Adult Only',
  'reservations.disabled': '[ES] Disabled',
  'reservations.hidden_overlap_shifts_info':
    '[ES] Shifts that overlap with the timing of your other service period are not available.',
  'reservations.table_availability_confirmation_dialog.title':
    '[ES] Are you sure you want to leave this page without applying your changes?',
  'reservations.table_availability_confirmation_dialog.discard': '[ES] Discard Changes',
  'reservations.table_availability_confirmation_dialog.apply': '[ES] Apply Changes',
  'reservations.select_floor_plan': '[ES] Select Floor Plan',
  'reservations.no_floor_plan_selected': '[ES] You currently have no Floor Plan selected.',
  'reservations.create_new_closure': '[ES] Create New Closure',
  'reservations.edit_closure': '[ES] Edit Closure',
  'reservations.create_new_override': '[ES] Create New Override',
  'reservations.edit_override': '[ES] Edit Override',
  'reservations.back_to_availability_planning': '[ES] Back to Availability Planning',
  'reservations.closure_details': '[ES] Closure Details',
  'reservations.override_details': '[ES] Override Details',
  'reservations.override_shift_name': '[ES] Shift',
  'reservations.closure_name': '[ES] Closure Name',
  'reservations.override_reason': '[ES] Reason',
  'reservations.optional_field': '[ES] (Optional)',
  'reservations.closure_applies': '[ES] Applies',
  'reservations.closure_frequency.once': '[ES] Once',
  'reservations.closure_frequency.weekly': '[ES] Weekly',
  'reservations.closure_frequency.daily': '[ES] Daily',
  'reservations.when': '[ES] When',
  'reservations.select_dates': '[ES] Select Dates',
  'reservations.service_period': '[ES] Service Period',
  'reservations.closure_type': '[ES] Closure Type',
  'reservations.edit_multiple_selections': '[ES] Edit Multiple Selections',
  'reservations.edit_tables_with_quantity':
    '[ES] Editing {{number}} tables and table combinations.',
  'reservations.mixed': '[ES] Mixed',
  'reservation.shift': '[ES] shift',
  'reservations.closure': '[ES] closure',
  'reservations.override': '[ES] override',
  'reservations.confirmation_modal.simple.title':
    '[ES] Are you sure you want to delete this {{item_type}}?',
  'reservations.confirmation_modal.simple.title2': '[ES] Deleting {{item_type}} can not be undone.',
  'reservations.confirmation_modal.message':
    '[ES] Are you sure you want to delete this {{item_type}}: {{item_name}} ?',
  'reservations.confirmation_modal.title':
    '[ES] Are you sure you want to delete the "{{item_name}}" {{item_type}}?',
  'reservations.action_create.success': '[ES] Your {{item_type}} has been created.',
  'reservations.action_update.success': '[ES] Your {{item_type}} has been updated.',
  'reservations.action_delete.success': '[ES] Your {{item_type}} has been deleted.',
  'reservations.action_delete.failure': 'Could not delete {{item_type}}: {{item_name}}',
  'reservations.online_booking': '[ES] Online Booking',
  'reservations.general_settings': '[ES] General Settings',
  'reservations.website_widgets': '[ES] Website Widgets',
  'reservations.booking_widgets': '[ES] Booking Widgets',
  'reservations.reserve_with_google': '[ES] Reserve with Google',
  'reservations.available_through': '[ES] Available through',
  'reservations.channel.widgets': '[ES] Website Widgets',
  'reservations.channel.tb_dine': '[ES] TouchBistro Dine',
  'reservations.channel.booking': '[ES] Online Booking Page',
  'reservations.channel.rwg': '[ES] Reserve with Google',
  'reservations.online_booking_window': '[ES] Online Booking Window',
  'reservations.online_booking_window.description':
    '[ES] How far in advance will guests be able to reserve online?',
  'reservations.online_booking_page': '[ES] Online Booking Page',
  'reservations.online_booking_page_link': '[ES] Online Booking Page Link',
  'reservations.online_booking_page_description':
    '[ES] Link guests to your TouchBistro Reservations Online Booking Page so they can easily reserve a table or join your waitlist.',
  'reservations.online_booking_page_copy.part_1':
    '[ES] This page is created for you using information you provide. The information (such as hours of operation, address, contact information, and more) can be updated by visiting the ',
  'reservations.online_booking_page_copy.part_2': '[ES] area of your account.',
  'reservations.venue_info': '[ES] Venue Info',
  'reservations.tbdine_profile_page': '[ES] TouchBistro Dine Profile Page',
  'reservations.tbdine_profile_page_copy.part_1':
    '[ES] Your profile is created for you using information you provide. The information (such as hours of operation, address, contact information, and more) can be updated by visiting the ',
  'reservations.tbdine_profile_page_copy.part_2': '[ES] area of your account.',
  'reservations.diner_notes': '[ES] Diner Notes',
  'reservations.diner_notes.description':
    '[ES] Use diner notes to communicate important information to guests who reserve or join the waitlist online.',
  'reservations.diner_notes.en_message.placeholder': '[ES] Enter Message',
  'reservations.diner_notes.en_message.error': '[ES] Oops! The character limit is 4000.',
  'reservations.minors': '[ES] Minors',
  'reservations.minors.description':
    '[ES] Turn this on if you want guests to indicate there are minors in their party.',
  'reservations.venue.name': '[ES] Venue: {{venue_details}} (Total)',
  'reservations.closure.full': '[ES] Full',
  'reservations.closure.partial': '[ES] Partial',
  'reservations.frequency.every': '[ES] Every',
  'reservations.frequency.everyday': '[ES] Everyday from',
  'reservations.from': '[ES] from',
  'reservations.booking_window_unit.minute': '[ES] Minutes',
  'reservations.booking_window_unit.hour': '[ES] Hours',
  'reservations.booking_window_unit.day': '[ES] Days',
  'reservations.booking_window_unit.week': '[ES] Weeks',
  'reservations.booking_window_unit.year': '[ES] Years',
  'reservations.select_shift_placeholder': '[ES] Select Shift',
  'reservations.enter_override_reason_placeholder': '[ES] Enter an Override reason',
  'reservations.select_frequency_placeholder': '[ES] Select Frequency',
  'reservations.select_service_period_placeholder': '[ES] Select Service Period',
  'reservations.rwg.description.part_1':
    '[ES] TouchBistro and Google drive more bookings together by making it easy to book online using Google Search and Maps. Visit the ',
  'reservations.rwg.description.part_2': '[ES]  for more information.',
  'reservations.rwg.online_walk_in_waitlist': '[ES] Online Walk-In Waitlist',
  'reservations.rwg.reserve_with_google_website': '[ES] Reserve with Google website',
  'reservations.rwg.online_walk_in_wailist.description':
    "[ES] Allow guests to join the Reserve with Google walk-in waitlist before they arrive. They'll even be automatically notified of their place in line.",
  'reservations.rwg.note': '[ES] Note',
  'reservations.rwg.note.description':
    "[ES]  must be turned ON to use Reserve with Google's Walk-in Waitlist.",
  'reservations.integration': '[ES] Integration',
  'reservations.rwg': '[ES] Reserve with Google',
  'reservations.examples': '[ES] Examples',
  'reservations.reserve_table': '[ES] Reserve a Table',
  'reservations.join_waitlist': '[ES] Join Waitlist',
  'reservations.reserve_table_join_waitlist': '[ES] Reserve a Table & Join Waitlist',
  'reservations.on_search': '[ES] On Search',
  'reservations.on_maps': '[ES] On Maps',
  'reservations.on_booking_page': '[ES] Booking Page',
  'reservations.on_join_waitlist_page': '[ES] Join Waitlist Page',
  'reservations.widget_location': '[ES] Location',
  'reservations.widget_location_title_default': '[ES] Select Venues',
  'reservations.widget_location_title': '[ES] {{count}} {{venue}} Selected',
  'reservations.widget_language': '[ES] Widget Language',
  'reservations.widget_type': '[ES] Widget Type',
  'reservations.widget_style': '[ES] Widget Style',
  'reservations.widget_type.row': '[ES] Row',
  'reservations.widget_type.col': '[ES] Column',
  'reservations.widget_type.block': '[ES] Block',
  'reservations.widget_style.default': '[ES] Default',
  'reservations.widget_style.branded': '[ES] Branded',
  'reservations.booking_widget.descr_1':
    "Easily embed the online booking form directly on your restaurant's website using a booking widget.",
  'reservations.booking_widget.descr_2':
    "Choose the style (or styles) you prefer, then copy and paste the widget code below into your website's HTML code.",
  'reservations.note': '[ES] Note',
  'reservations.booking_widget.descr_3.1':
    '[ES] To customize the branding of your Booking Widgets, go to the ',
  'reservations.branding': '[ES] Branding',
  'reservations.booking_widget.descr_3.2': '[ES]  area of Venue Info.',
  'reservations.booking_widget_missing_location.descr_1':
    '[ES] Select at least 1 Location to generate a preview of your Booking Widget',
  'reservations.booking_widget_missing_location.descr_2':
    '[ES] Select at least 1 location to generate your Booking Widget Code',
  'reservations.heads_up': '[ES] Heads up',
  'reservations.heads_up.descr_1': '[ES] Your ',
  'reservations.heads_up.descr_2': '[ES]  needs to be set up before guests can book online.',
  'reservations.widget_branding_cta.descr_1':
    '[ES] Did you know you can apply your brand colors to Booking Widgets?',
  'reservations.widget_branding_cta.descr_2.1': '[ES] Visit the ',
  'reservations.widget_branding_cta.descr_2.2': '[ES]  area of Venue Info to set this up.',
  'reservations.here': '[ES] here',
  'reservations.widget_branding_block.descr_1': '[ES] Click ',
  'reservations.widget_branding_block.descr_2':
    '[ES]  to customize Widgets with your brand colors.',
  'reservations.booking_widget_code': '[ES] Booking Widget Code',
  'reservations.booking_iframes': '[ES] Booking Iframes',
  'reservations.booking_iframes.descr':
    "[ES] An iframe can be used to include the TouchBistro Dine booking website on your restaurant's website so diners can book a reservation without having to leave your website. To use an iframe copy and paste the iframe code below into your website's HTML code.",
  'reservations.booking_iframe_code': '[ES] Booking Iframe Code',
  'reservations.sp_cutoff_time': '[ES] SP1 / SP2 Cutoff Time',
  'reservations.communications': '[ES] Communications',
  // errors
  'reservations.error.max-party-size-limit': '[ES] Maximum party size is 10',
  'reservations.error.min-max-party-size':
    '[ES] Minimum Party size can not be more than Maximum Party Size',
  'reservations.error.max-min-party-size':
    '[ES] Maximum Party size can not be less than Minimum Party Size',
  'reservations.error.character_limit': '[ES] {{field}} must be up to {{limit}} characters long.',
  'reservations.error.shift_name_unique':
    '[ES] This Shift name is already in use. Please enter a unique Shift name.',
  'reservations.error.first_last_seating_same':
    '[ES] First and Last Seating time cannot be the same.',
  'reservations.error.scheduling_conflict_header':
    '[ES] Oops! These seating times overlap with another scheduled shift:',
  'reservations.error.scheduling_conflict_footer':
    '[ES] To continue, please update the seating times or remove this shift from the recurring schedule.',
  'reservations.error.shiftwide_pacing_invalid_combo':
    '[ES] Please enter a valid max parties/max guests combination.',
  'reservations.error.zero_turn_time': '[ES] Expected turn time cannot be less than 15 minutes.',
  'reservations.error.parties_exceed_guests':
    '[ES] Number of parties cannot exceed the number of guests.',
  'reservations.error.zero_parties_and_guests':
    '[ES] When the number of parties is 0, the number of guests has to be 0.',
  'reservations.error.zero_guest_and_parties':
    '[ES] When the number of guests is 0, the number of parties has to be 0',
  'reservations.error.guests_less_than_parties':
    '[ES] Number of guests cannot be less than number of parties.',
  'reservations.error.min_max': '[ES] Oops! Minimum should be less than Maximum.',
  'reservations.error.max_duration': '[ES] Oops! 1 year is the limit.',
  'reservations.error.empty_shift_override_selection':
    '[ES] Please select a Shift in order to create an Override.',
  'reservations.error.empty_frequency_closure_selection':
    '[ES] Please choose how often you want your Closure applied.',
  'reservations.error.empty_frequency_override_selection':
    '[ES] Please choose how often you want your Override applied.',
  'reservations.error.empty_period_override_selection':
    '[ES] Please choose the Service Period you want your Override applied.',
  'reservations.error.conflict_more': '[ES] + {{count}} more',
  'reservations.shifts_delete.tooltip.inuse': '[ES] (In use)',
}

export default reservationsES
