import axios from 'axios'

import { SessionErrorCodes } from '~/src/shared/errors/session_error'
import { StatusCodes } from '~/src/shared/types'

// TODO > map all errors using i18n
export enum ServerErrorCodes {
  err_unknown_code = 'err_unknown_code',
  err_verification_expired = 'err_verification_expired',
  err_invalid_venue_state = 'err_invalid_venue_state',
  err_signup_consumed = 'err_signup_consumed',
  err_signup_resent = 'err_signup_resent',
  err_signup_expired = 'err_signup_expired',
  err_signup_cancelled = 'err_signup_cancelled',
  err_unlicensed_venue = 'err_unlicensed_venue',
  err_invite_expired = 'err_invite_expired',
  err_invite_consumed = 'err_invite_consumed',
  err_invite_cancelled = 'err_invite_cancelled',
  err_invite_not_found = 'err_invite_not_found',
}

export type ParsedError = {
  statusCode: StatusCodes
  message: string
  code: ServerErrorCodes | SessionErrorCodes
}

export function isResponseError(response: unknown): response is {
  code?: string
  message?: string
  error?: { code?: string; message?: string }
} {
  if (typeof response === 'object' && response) {
    return true
  }
  return false
}

export function parseRequestError(err: unknown): ParsedError {
  // init an standard unknown error
  const standardError: ParsedError = {
    code: ServerErrorCodes.err_unknown_code,
    statusCode: StatusCodes.SERVER_ERROR,
    message: 'Internal Server Error',
  }

  if (typeof err === 'object' && err !== null) {
    // null is of type object, please do not remove the test above

    if (axios.isAxiosError(err)) {
      // status
      if (typeof err.response?.status === 'number') {
        standardError.statusCode = err.response.status
      }

      if (isResponseError(err.response?.data)) {
        // code
        if (typeof err.response?.data.code === 'string') {
          standardError.code = err.response.data.code as ServerErrorCodes
        }

        if (typeof err.response?.data.error?.code === 'string') {
          standardError.code = err.response.data.error.code as ServerErrorCodes
        }

        // message
        if (typeof err.response?.data.error?.message === 'string') {
          standardError.message = err.response.data.error.message
        }

        if (typeof err.response?.data.message === 'string') {
          standardError.message = err.response.data.message
        }
      }
    }

    // It's a standard error or an http-client error
    else {
      const errorObj = err as Record<string, unknown>
      // status
      if (typeof errorObj.statusCode === 'number') {
        standardError.statusCode = errorObj.statusCode
      }

      if (typeof errorObj.status === 'number') {
        standardError.statusCode = errorObj.status
      }

      // code
      if (typeof errorObj.code === 'string') {
        standardError.code = errorObj.code as ServerErrorCodes
      }

      // message
      if (typeof errorObj.message === 'string') {
        standardError.message = errorObj.message
      }
    }
  }

  return standardError
}
