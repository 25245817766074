import { datadogRum } from '@datadog/browser-rum'
import browserConfig from '~/src/shared/config/browser'

if (browserConfig.environment === 'production') {
  datadogRum.init({
    service: 'venue-ark-frontend',
    applicationId: browserConfig.ddRumApplicationId,
    clientToken: browserConfig.ddRumClientToken,
    site: 'datadoghq.com',
    replaySampleRate: 100,
    sampleRate: 50,
    useCrossSiteSessionCookie: true,
    version: process.env.NEXT_PUBLIC_GITSHA,
    allowedTracingOrigins: [/https:\/\/.*\.touchbistro\.com/],
    trackInteractions: true,
    trackFrustrations: true,
    enableExperimentalFeatures: ['feature_flags'],
  })

  datadogRum.startSessionReplayRecording()
}
